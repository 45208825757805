.menu {
    color : #C2BFC3;
}
.menu.active {
    color : #49B0C2;
}

/* .menu-container {
    background: yellow;
    width: 100%;
    height: 50px;
} */
/* .menu-container:before {
    content: "";
    display: flex;
    position: ab;
    vertical-align: top;
    width: 100px;
    height: 100px;
    background-color: red;
    background: crimson url("https://i.stack.imgur.com/Fogjj.jpg") center / cover;
    border: 10px solid white;
    border-radius: 50%;
    box-sizing: border-box;
    margin-top: -50px;
  } */